import { useState } from "react";
import React, { createContext, useContext, useEffect } from "react";
import { Provider } from "react-redux";
import { productItemHome } from "../../pages/home";
import { productData } from "../../pages/products/mockData";
import { store } from "../store";
import Banner from "./banner/Banner";
import BestSeller from "./best-seller/BestSeller";
import Brands from "./brands/Brands";
import CategoriesItems from "./categories-items/CategoriesItems";
import CostInfo from "./cost-info/CostInfo";
import FeaturedProducts from "./featured-products/FeaturedProducts";
import Network from "./network/Network";
import ProductsCategory from "./products-category/ProductsCategory";
import ProductItem from "./products/product/ProductItem";
import Products from "./products/Products";
import ServiceList from "./service-list/ServiceList";
import TodayDeals from "./today-deals/TodayDeals";
import TrendingProducts from "./trending-products/TrendingProducts";
import AddingShow from "./network/AddingShow";
import HandicraftImage from "./handicraft-image/HandicraftImage";
import home_page_1 from "../../assets/img/home_page_1-01.webp";
import home_page_2 from "../../assets/img/home_page_2-01.webp";
import Home_Bar from "../../assets/img/Home_Bar.jpg";
import Gift_banner from "../../assets/img/Gift_banner.webp";
import studio from "../../assets/img/studio.mp4";
import CatagProduct from "./CatagPro/CatagProduct";
import axios from "axios";
import { base_url } from "../../server";
import PopularProduct from "./popular";
import { Link } from "react-router-dom";
function Home() {
  const productData = useContext(productItemHome);
  const baseUrl = base_url();
  const [data, setData] = useState(null);
  const getata = async () => {
    try {
      const res = await axios.get(`${baseUrl}category/filter/categ`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getata();
  }, []);
  return (
    <>
      <Banner />
      {/* <ServiceList /> */}
      <ProductsCategory />
      <PopularProduct />
      {/* <Products productData={productData} /> */}

      <FeaturedProducts />
      {data &&
        data?.slice(0, 3).map((item, i) => {
          return <CatagProduct key={i} item={item} />;
        })}

      {/* <TrendingProducts /> */}
      {/* <TodayDeals /> */}
      <section className="sellerCategorySec d-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <CategoriesItems />
            </div>
          </div>
        </div>
      </section>

      {/* <BestSeller /> */}
      {/* <Network /> */}
      {/* <AddingShow /> */}

      {/* <HandicraftImage /> */}
      {/* <CostInfo /> */}

      <div className="studioVD">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <video
                src={studio}
                loop
                autoPlay="true"
                muted
                height="350"
                width="100%"
              />
            </div>
            <div className="col-md-3">
              <div className="firstLook">
                <div className="fisherman-content">
                  <h3>First Look Articles </h3>
                </div>
                <img src={Home_Bar} alt="First Look" className="img-fluid" />
                <h5>Home Barware Essentials For Festive Indulgences</h5>
                <p>
                  Holidays are about parties and meetings with friends and
                  relatives, jokes, and tasty and beloved...{" "}
                  <Link to="/">Read more</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="dealDaySec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="fisherman-content">
                <h3>Deals of the day</h3>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="dealDayInfo"
                style={{ backgroundImage: `url(${home_page_1})` }}
              >
                <p>GET FREE GLASS STRAW- PACK OF 20</p>
                <span>Use Code: FREEONE</span>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="dealDayInfo"
                style={{ backgroundImage: `url(${home_page_2})` }}
              >
                <p>Get Flat 500 OFF</p>
                <span>USE CODE: EPIC500</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="percentSec sectionPD">
        <div className="container">
          <div
            className="percentInfo"
            style={{
              backgroundImage: `url(${Gift_banner})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <h3>Get on the list</h3>
            <p>
              Perks include 10 % off your first online order. Be the first to
              know <br />
              about new collections, store launches, sales, and much more!
            </p>

            <form className="signMe">
              <input
                type="mail"
                name="mail"
                className="form-control"
                placeholder="Enter your email address here"
              />
              <button className="btn signButton">Sign me up</button>
            </form>
          </div>
        </div>
      </section>

      <Brands />
    </>
  );
}

export default Home;

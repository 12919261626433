import React, { useContext, useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import ReactImageZoom from "react-image-zoom";
import Slider from "react-slick";

import Review from "./review/Review";
import Breadcrumb from "../../../shared/breadcrumb/Breadcrumb";
import ProductDetailContent from "./product-detail-content/ProductDetailContent";
import SelectImageSlider from "./select-image-slider/SelectImageSlider";
import { BsFillCartFill, BsShareFill } from "react-icons/bs";
import { RiDownloadCloudFill } from "react-icons/ri";

import { productItemContext } from "../../../pages/product-detail";

import "./ProductDetail.css";
import {
  useSetCartMutation,
  setCartLists,
  useOfflineAddPostMutation,
  useSendComboMutation,
  setCartLeng,
} from "../../products/productSlice";
import { useNavigate, useParams } from "react-router-dom";
import { CustomToaster } from "../../../common/toaster/CustomToaster";
import { ImgesData } from "../proImgs/ImgesData";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ModalQuatation from "./ModalQutation";
// swiper -------------------------------//

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import FeaturedProduct from "../featured-products/featured-product/FeaturedProduct";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { base_url } from "../../../server";
function createMarkup(data) {
  return { __html: data };
}
function ProductDetail() {
  const { t, i18n } = useTranslation();
  const userid = window.localStorage.getItem("user_id");
  const isLogin = window.localStorage.getItem("isLogin");

  const [similorItems, setSimilorItems] = useState([]);
  const [
    addToCart,
    {
      data: datacart,
      isLoading: isAddCartLoading,
      isSuccess: isAddToCartSuccess,
      isError: isAddToCartError,
    },
  ] = useSetCartMutation();
  const [showTaoster, setShowToaster] = useState({
    show: false,
    message: "",
    color: "success",
  });
  const [productAdded, setProductAdded] = useState(false);
  const productData = useContext(productItemContext);
  const [mainImage, setMainImage] = useState(ImgesData[0]);
  const isProductAdded = window.localStorage.getItem("currentProductId");

  const [varint, setVariants] = useState({});
  const params = useParams();
  const [data, setData] = useState(null);
  const [isProduceDetailLoading, setisProduceDetailLoading] = useState(true);
  const [isError, setisError] = useState(false);
  const [productId, setProductId] = useState("");
  const token = window.localStorage.getItem("token");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isloadingVal, setIsloadingVal] = useState(true);
  const sliderRef = useRef();
  const baseUrl = base_url();
  const [filterState, setFilterState] = useState({
    categories: [],
    brands: [],
    minPrice: "",
    maxPrice: "",
    sort: "1",
  });
  const getAllDeta = async () => {
    const userId = userid ? userid : null;
    try {
      const user = await axios.get(`${baseUrl}product/public/${params._id}`, {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      });

      setData(user.data.product);
      getFilterd(user?.data.product?.category_id[0]?.uid);
      window.localStorage.setItem(
        "sellerId",
        user?.data?.product?.variations[0]?.prices[0]?.seller_id?._id
      );
      window.localStorage.setItem(
        "sku",
        user?.data?.product?.variations[0]?.prices[0]?.sku
      );
      setisProduceDetailLoading(false);
    } catch (error) {
      setisError(true);
      setisProduceDetailLoading(false);
    }
  };

  const getFilterd = async (id) => {
    try {
      const res = await axios.post(
        `${baseUrl}product/filter`,
        { ...filterState, categories: [id] },
        { withCredentials: true }
      );
      setSimilorItems(res.data);
      setIsloadingVal(false);
    } catch (error) {
      alert("Filter Not Apply");
      setIsloadingVal(false);
    }
  };
  useEffect(() => {
    getAllDeta();
    window.localStorage.setItem("variationsId", null);
    console.log(params._id);
    setProductId(params._id);
  }, [params._id]);
  let imgs = mainImage?.url;
  if (data?.getaProduct?.mainimage_url?.url) {
    imgs = data?.getaProduct?.mainimage_url?.url;
  }

  const [zoomImageProps, setzoomImageProps] = useState({
    width: 530,
    height: 450,
    zoomWidth: 500,
    img: imgs,
  });

  const handleUniqueID = (currentElm) => {
    setMainImage(currentElm);
    setzoomImageProps({ ...zoomImageProps, img: currentElm.url });
  };

  const selllerId = window.localStorage.getItem("sellerId");
  let selId = selllerId;

  const handleAddCart = () => {
    const product_count = window.localStorage.getItem("productCount");
    const pickupPoint = window.localStorage.getItem("pickUpPoint");

    const deliveryType = window.localStorage.getItem("deliveryType");
    const product_id = params._id;
    const product_variant = window.localStorage.getItem("variationsId");

    // selId = '651bb0c828441564809cc1d4'
    if (!product_variant) {
      setShowToaster({
        show: true,
        message: "Something went wrong! Select Atleast  1 Variant",
        color: "danger",
      });
    }
    if (pickupPoint == "null") {
      const payload = {
        qty: product_count,
        pickupPoint: pickupPoint,
        variantId: product_variant,
        productId: params._id,
        deliveryType,
        // userid,
        seller_id: selllerId,
        sku: window.localStorage.getItem("SKU"),
      };
      addToCart(payload);
    } else {
      const payload = {
        qty: product_count,
        pickupPoint: pickupPoint,
        variantId: product_variant,
        productId: params._id,
        deliveryType,
        // userid,
        seller_id: selllerId,
        sku: window.localStorage.getItem("SKU"),
      };
      addToCart(payload);
    }
  };

  const handleToaster = () => {
    setShowToaster({ ...showTaoster, show: false });
  };
  const dispacher = useDispatch();

  const [mergsData, setMergsData] = useState(data?.images);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (data) {
      if (data?.isGlobalImage) {
        const clone = {
          width: 530,
          height: 450,
          zoomWidth: 500,
          img: data?.mainImage_url?.url,
        };
        setzoomImageProps(clone);
        setSiliderImage(data?.images);
      } else {
        const clone = {
          width: 530,
          height: 450,
          zoomWidth: 500,
          img: data?.variations[0]?.mainImage_url?.url,
        };
        setzoomImageProps(clone);
        setSiliderImage(data?.variations[0]?.images);
      }
    }
  }, [data]);

  const setSiliderImage = (images) => {
    const cloen = images;
    // const obj = { url: clone?.img };
    // cloen.push(obj);
    setMergsData(cloen);
  };

  useEffect(() => {
    if (isAddToCartSuccess) {
      dispacher(setCartLeng(datacart?.cartLength));
      window.localStorage.setItem("cartItem", datacart?.cart);
      setShowToaster({
        show: true,
        message: "Product added successfully!",
        color: "success",
      });
    }
    if (isAddToCartError) {
      setShowToaster({
        show: true,
        message: "Something went wrong Product Not Add",
        color: "danger",
      });
    }
    if (isProductAdded) {
      setProductAdded(true);
    } else {
      setProductAdded(false);
    }
  }, [isAddToCartSuccess, isAddToCartError, isProductAdded]);

  const shortVariant = (data) => {
    setVariants({ ...data });
  };
  const product_variant = window.localStorage.getItem("variationsId");
  const { updatedProducts: products } = useSelector((state) => {
    return state.productList;
  });
  const [
    postOffline,
    { data: resData, isLoading, isSuccess, isError: offErr },
  ] = useOfflineAddPostMutation();

  const notLogin = (id) => {
    const product_count = window.localStorage.getItem("productCount");
    const pickupPoint = window.localStorage.getItem("pickUpPoint");

    const deliveryType = window.localStorage.getItem("deliveryType");
    const product_variant = window.localStorage.getItem("variationsId");
    if (selllerId === null) {
      selId = "64269f0df127906d53878d3d";
    }
    if (!Object.keys(varint).length) {
      setShowToaster({
        show: true,
        message: "Something went wrong! Select Atleast  1 Variant",
        color: "danger",
      });
    } else {
      if (pickupPoint == "null") {
        postOffline({
          product_count: product_count,
          product_variant: product_variant,
          deliveryType,
          seller_id: selId,
          sku: window.localStorage.getItem("SKU"),
          product_id: params._id,
          pickupPoint: null,
          products: products ? products : "",
          // minimum_order_qty: data?.minimum_order_qty,
          // total_quantity: data?.total_quantity,
        });
      } else {
        postOffline({
          product_count: product_count,
          product_variant: product_variant,
          deliveryType,
          seller_id: selId,
          sku: window.localStorage.getItem("SKU"),
          product_id: params._id,
          pickupPoint: pickupPoint,
          // minimum_order_qty: data?.minimum_order_qty,
          // total_quantity: data?.total_quantity,
        });
      }
    }
  };
  useEffect(() => {
    if (isSuccess) {
      setShowToaster({
        show: true,
        message: "Product added successfully!",
        color: "success",
      });
      dispacher(setCartLists(resData.cart.products));
    }
    if (offErr) {
      setShowToaster({
        show: true,
        message: "Product Not Added!",
        color: "danger",
      });
    }
  }, [isSuccess, offErr]);

  const [varientsval, setVariantsval] = useState(null);

  const navigate = useNavigate();
  const [count1, setCount1] = useState(0);
  const BuyNowItem = (item) => {
    const payload = {
      qty: 1,
      pickupPoint: null,
      variantId: item.variations[count1]?.uid,
      productId: item.uid,
      deliveryType: "HOME DELIVERY",
      seller_id: item.variations[count1]?.prices[0].seller_id?._id,
      sku: item.variations[count1]?.prices[0].sku,
    };
    addToCart(payload);
    setTimeout(() => {
      navigate("/checkout");
    }, 1000);

    setTimeout(() => {
      navigate("/checkout");
    }, 1000);
  };

  const [
    sendCombos,
    { isLoading: isLoadingcomb, isError: isErrorcomb, isSuccess: issusscomb },
  ] = useSendComboMutation();

  const [dilevType, setdilevType] = useState("HOME DELIVERY");
  const homeDelever = (str) => {
    setdilevType(str);
  };

  const sendCombo = (id) => {
    if (dilevType === "HOME DELIVERY") {
      sendCombos({
        userid: userid,
        combo_id: id,
        deliveryType: dilevType,
        pickupPoint: null,
      });
    } else {
      console.log(dilevType);
      sendCombos({
        userid: userid,
        combo_id: id,
        deliveryType: dilevType,
        pickupPoint: null,
      });
      alert("Combo Not Added");
    }
    setTimeout(() => {
      navigate("/cart");
    }, 1000);
  };

  useEffect(() => {
    if (isErrorcomb) {
      alert("Server Error Combo Product Not Add");
    }
    if (issusscomb) {
      alert("Combo Product Add Successfully");
    }
  }, [isErrorcomb, issusscomb]);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  const changeImage = (item) => {
    if (item?.images?.length && !data.isGlobalImage) {
      setSiliderImage(item?.images);
      setzoomImageProps({ ...zoomImageProps, img: item.images[0].url });
    }
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const settings2 = {
    speed: 500,
    slidesToShow: 7, // Number of items to show on desktop
    slidesToScroll: 1,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024, // Screen size for tablets
        settings: {
          slidesToShow: 7, // Number of items to show on tablets
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Screen size for mobile devices
        settings: {
          infinite: true,
          slidesToShow: 3, // Number of items to show on mobile
          slidesToScroll: 1,
          // arrows: false,
        },
      },
    ],
  };
  const handleSmallItemClick = (index) => {
    setCurrentSlide(index);
    sliderRef.current.slickGoTo(index);
  };
  return (
    <>
      <Helmet>
        <title>MAQ The World Crockery| Product Details</title>
        <meta name="keyword" content={"MAQ The World Crockery,Baby Products"} />
        <meta
          name="description"
          content={`${data?.getaProduct?.meta_description}`}
        />
      </Helmet>
      <Breadcrumb title={data?.getaProduct?.name} show={true} t={t} />
      <ModalQuatation show={show} setShow={setShow} />
      {/* Start Product Details Area */}
      <CustomToaster
        color={showTaoster.color}
        title={data?.getaProduct?.name}
        show={showTaoster.show}
        setShow={handleToaster}
        message={showTaoster.message}
        position="bottom-end"
        delay={10000}
      />
      <section className="product-details-area">
        {/* {isLoading && <div className="preloaderCount">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>} */}
        {/* {isAddCartLoading && <div className="preloaderCount">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>      
        </div>} */}
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="SelectImageSec d-flex">
                {!isMobile && (
                  <div className="SelectImg">
                    {data && (
                      <SelectImageSlider
                        productData={mergsData}
                        handleUniqueID={handleUniqueID}
                      />
                    )}
                  </div>
                )}

                <div
                  className="product-details-image"
                  style={!isMobile ? { zIndex: "10" } : { zIndex: "0" }}
                >
                  {isMobile && (
                    <div className="mobileBanner">
                      <Slider {...settings} ref={sliderRef}>
                        {!!mergsData?.length &&
                          mergsData.map((item, index) => {
                            return (
                              <div className="image">
                                <img src={item?.url} />
                              </div>
                            );
                          })}
                      </Slider>
                      <div className="small-items">
                        {!!mergsData?.length && (
                          // <SelectImageSlider
                          //   productData={mergsData}
                          //   handleUniqueID={handleSmallItemClick}
                          // />
                          <Slider {...settings2}>
                            {mergsData?.map((item, i) => {
                              return (
                                <div
                                  className="productSliderItem"
                                  key={i}
                                  style={{ marginRight: "10px" }}
                                >
                                  <img
                                    src={item.url}
                                    className="img-fluid"
                                    alt="images"
                                    onClick={() => {
                                      handleSmallItemClick(i);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </Slider>
                        )}
                      </div>
                    </div>
                  )}
                  {!isMobile && zoomImageProps?.img && (
                    <ReactImageZoom
                      // style={{ zIndex: "9999999", position: "relative" }}
                      {...zoomImageProps}
                    />
                  )}

                  <div className="shareProductSec button">
                    <div className="titleText">
                      <BsShareFill />
                      <h6>{t("Share")}</h6>
                    </div>
                    <div className="shareProduct">
                      <ul>
                        <li>
                          <FacebookShareButton url={window.location.href}>
                            <FacebookIcon
                              logofillcolor="white"
                              round={true}
                            ></FacebookIcon>
                          </FacebookShareButton>
                        </li>

                        <li>
                          <WhatsappShareButton url={window.location.href}>
                            <WhatsappIcon
                              logofillcolor="white"
                              round={true}
                            ></WhatsappIcon>
                          </WhatsappShareButton>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {data?.comboDeals?.length > 0 && (
                    <div className="comboShowBox">
                      {data?.comboDeals[0]?.products.map((item, i) => {
                        return (
                          <div>
                            <div className="innerCombo" key={i}>
                              <div>
                                <img
                                  style={{ width: "110px", height: "110px" }}
                                  src={item?.image?.url}
                                  alt="images"
                                />
                              </div>
                              <div>
                                Product Name : {item.name} , variant :{" "}
                                {item?.variant[0].weight}
                              </div>
                            </div>
                            {data?.comboDeals[0]?.products.length === i + 1 ? (
                              <span></span>
                            ) : (
                              <span>+</span>
                            )}
                          </div>
                        );
                      })}
                      <div className="btncomboSec">
                        <div style={{ margin: "10px 0" }}>
                          <h5>
                            Offer Price : {data?.comboDeals[0]?.offer_Price}
                          </h5>
                        </div>
                        <ul
                          className="deliveryModeList storageNumber"
                          id="myTab"
                          role="tablist"
                        >
                          <li role="presentation">
                            <button
                              className={`nac-link`}
                              // className={`nac-link ${!str && 'active'}`}
                              id="homeDelivery-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#homeDelivery"
                              type="button"
                              role="tab"
                              aria-controls="homeDelivery"
                              aria-selected="true"
                              onClick={() => homeDelever("HOME DELIVERY")}
                            >
                              Home Delivery
                            </button>
                          </li>

                          <li role="presentation">
                            <button
                              className={`nac-link`}
                              // className={`nac-link ${!str && 'active'}`}
                              id="pickup-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#pickup"
                              type="button"
                              role="tab"
                              aria-controls="pickup"
                              aria-selected="true"
                              onClick={() => homeDelever("pickuppoint")}
                            >
                              Pickup From Store
                            </button>
                          </li>
                        </ul>
                        <button
                          type="Button"
                          className="default-btn buy"
                          onClick={() => {
                            sendCombo(data?.comboDeals[0]._id);
                          }}
                        >
                          <BsFillCartFill />
                          Buy it now!
                        </button>
                      </div>
                      {isLoadingcomb && (
                        <div className="preloaderCount">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <ProductDetailContent
                t={t}
                count1={count1}
                setCount1={setCount1}
                setVariantsval={setVariantsval}
                getAllDeta={getAllDeta}
                shortVariant={shortVariant}
                value={data}
                data={data}
                wish={data?.wish}
                isError={isError}
                isLoading={isProduceDetailLoading}
                isAddToCartSuccess={isAddToCartSuccess}
                isAddToCartError={isAddToCartError}
                changeImage={changeImage}
                handleShow={handleShow}
                handleAddCart={handleAddCart}
                isAddCartLoading={isAddCartLoading}
                BuyNowItem={BuyNowItem}
              />
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="tab products-details-tab">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <ul className="tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link active"
                          id="description-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#description"
                          type="button"
                          role="tab"
                          aria-controls="description"
                          aria-selected="true"
                        >
                          <div className="dot"></div>
                          {t("Description")}
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          id="additional-information-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#additional-information"
                          type="button"
                          role="tab"
                          aria-controls="additional-information"
                          aria-selected="false"
                        >
                          <div className="dot"></div>
                          {t("Additional information")}
                        </a>
                      </li>

                      {!!data?.video_link && (
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link"
                            id="video-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#video"
                            type="button"
                            role="tab"
                            aria-controls="video"
                            aria-selected="false"
                          >
                            <div className="dot"></div>
                            {t("Video")}
                          </a>
                        </li>
                      )}
                      {/* <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          id="brochure-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#brochure"
                          type="button"
                          role="tab"
                          aria-controls="brochure"
                          aria-selected="false"
                        >
                          <div className="dot"></div>
                          {t("Brochure")}
                        </a>
                      </li> */}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab_content tab-pane fade show active"
                        id="description"
                        role="tabpanel"
                        aria-labelledby="description-tab"
                      >
                        <div className="tabs_item">
                          <div
                            className="products-details-tab-content"
                            dangerouslySetInnerHTML={createMarkup(
                              data?.productDescription
                            )}
                          />
                        </div>
                      </div>
                      <div
                        className="tab_content tab-pane fade"
                        id="additional-information"
                        role="tabpanel"
                        aria-labelledby="additional-information-tab"
                      >
                        <div className="tabs_item">
                          <div className="products-details-tab-content">
                            <ul className="additional-information">
                              <li>
                                <span>
                                  <strong>Brand:</strong>
                                </span>
                                <span>{data?.brand_id?.name}</span>
                              </li>
                              {/* {} */}
                              {data?.isGlobalAttribute
                                ? data?.attributeList.map((item) => {
                                    return (
                                      <li>
                                        <span>
                                          <strong>
                                            {item?.name || "Name"}:
                                          </strong>
                                        </span>
                                        <span>
                                          {item?.list?.map((val, i) => {
                                            return <span>{val.value}</span>;
                                          })}
                                        </span>
                                      </li>
                                    );
                                  })
                                : data?.variations?.map((item) => {
                                    return (
                                      item?.uid === product_variant &&
                                      item.attributeList.map((item2) => {
                                        return (
                                          <li>
                                            <span>
                                              <strong>
                                                {item2?.attributeSetMaster
                                                  ?.name || "Name"}
                                                :
                                              </strong>
                                            </span>
                                            <span>
                                              {item2?.list?.map((val, i) => {
                                                return <span>{val.value}</span>;
                                              })}
                                            </span>
                                          </li>
                                        );
                                      })
                                    );
                                  })}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab_content tab-pane fade"
                        id="brochure"
                        role="tabpanel"
                        aria-labelledby="brochure-tab"
                      >
                        <div className="tabs_item">
                          <div className="products-details-tab-content">
                            <a
                              className="downloadBtn"
                              href="https://www.fertilizer.org/images/Library_Downloads/2016_Nutrient_Management_Handbook.pdf"
                              download
                              target="_blank"
                            >
                              <RiDownloadCloudFill />{" "}
                              <span className="txt">Download</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab_content tab-pane fade"
                        id="video"
                        role="tabpanel"
                        aria-labelledby="video-tab"
                      >
                        <div className="tabs_item">
                          <div className="products-details-tab-content">
                            {/* <video width="400" controls>
                              <source src={`${data?.video_link}`} type="video/mp4" />
                              <source src={`${data?.getaProduct?.video_link}`} type="video/ogg"/>
                              Your browser does not support HTML video.
                            </video> */}
                            <ReactPlayer
                              url={data?.video_link} // Replace with your video URL
                              controls={true}
                              width="100%"
                              height="auto"
                            />
                            {/*                             
                            <iframe width="560" height="315" src={data?.video_link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen={true}></iframe> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div style={{ margin: "20px 0" }}>
                <h5>Meta Title : {data?.getaProduct?.meta_title} </h5>
                <h5>Meta Description : {data?.getaProduct?.meta_description}</h5>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="productsSection  p-30">
        <div className="container">
          <div className="row featuredRow">
            <div className="col-lg-12">
              <div className="fishermanHeader ">
                <div className="fisherman-content">
                  <h3 style={{ display: "flex", justifyContent: "center" }}>
                    {t("Similar items")}
                  </h3>
                  <p></p>
                </div>
                {/* <div className="fisherman-btn d-none">
                  <Link to="/products" className="optional-btn">
                    View More
                  </Link>
                </div> */}
              </div>
            </div>
            <FeaturedProduct
              data={similorItems}
              isLoading={isloadingVal}
              handleShow={handleShow}
            />
          </div>
        </div>

        {/* {modelDataId && (
          <QuiekViewModal modelDataId={modelDataId} show={show} onHide={handleClose} size="xl"
            centered />
        )} */}
      </section>

      <Review ids={params._id} />
      {/* <Review ids={window.localStorage.getItem('variant_id')} /> */}
      {/* {data && <RelatedProducts productData={data} />} */}
      {/* End Product Details Area */}
    </>
  );
}

export default ProductDetail;
